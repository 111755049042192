
import { Component, Vue, Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import Navigation from '@/components/navigation.vue';
import AppBar from '@/components/app-bar.vue';
import PhoneCodeForm from '@/components/phone-code-form.vue';
import AppFooter from '@/components/app-footer.vue';
import Notifications from '@/components/notifications.vue';
import { ClientDto, PackageDto, ResourceDto } from '@torch-dev/kc-server-api';
import { AppConfiguration, AppResources } from '@/services/appConfiguration';

@Component({
  name: 'App',
  components: { Notifications, AppFooter, PhoneCodeForm, Navigation, AppBar },
  computed: mapState(['client', 'configuration', 'resources']),
  methods: mapActions(['setAppConfiguration', 'loadAuthorizedClient', 'loadAvailablePackages', 'loadAvailableResources'])
})
export default class extends Vue {
  configuration!: AppConfiguration;
  resources!: AppResources;
  client!: ClientDto | null;
  loadAuthorizedClient!: () => Promise<ClientDto | null>;
  loadAvailablePackages!: () => Promise<PackageDto[]>;
  loadAvailableResources!: () => Promise<ResourceDto[]>;

  appIsLoaded = false;
  authDialogIsVisible = false;
  drawerIsVisible = false;
  backendIsUnavailable = false;

  created (): void {
    Promise.all([
      this.checkAuthorizedClient(),
      this.loadAvailablePackages(),
      this.loadAvailableResources()
    ]).then(() => {
      this.setAppTitle();
      this.setFavicon();
      this.appIsLoaded = true;
    });
  }

  setAppTitle () {
    this.$nextTick(() => {
      document.title = this.configuration.title;
    });
  }

  setFavicon () {
    this.$nextTick(() => {
      if (this.resources.faviconUrl) {
        const link: HTMLLinkElement = document.createElement('link');
        link.rel = 'icon';
        link.href = this.resources.faviconUrl;
        document.head.appendChild(link);
      }
    });
  }

  mounted () {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 600 && this.drawerIsVisible) this.drawerIsVisible = false;
    });

    this.$root.$on('open-auth-form', () => {
      this.authDialogIsVisible = true;
    });

    this.$root.$on('sign-in-code-verified', () => {
      this.checkAuthorizedClient();
    });

    this.$router.beforeEach((to, from, next) => {
      this.authDialogIsVisible = false;
      next();
    });
  }

  async checkAuthorizedClient () {
    try {
      await this.loadAuthorizedClient();
      await this.routeToAppropriatePage();
    } catch (e) {
      await this.$router.push('/auth');
      this.backendIsUnavailable = true;
      console.error('Auth request failed. Site switched to maintenance mode.');
      document
        .querySelector('input')
        ?.setAttribute('disabled', 'true');
      document
        .querySelector('html')
        ?.setAttribute('style', 'overflow: hidden');
    }
  }

  onAuthBtnClicked () {
    this.$root.$emit('open-auth-form');
  }

  @Watch('client')
  async routeToAppropriatePage () {
    const isAuthorized = this.client !== null;
    const path = this.$router.currentRoute.path;
    let nextPath = path;

    if (isAuthorized && (path === '/' || path === '/auth' || path.startsWith('/payment'))) nextPath = '/dashboard';
    if (!isAuthorized) nextPath = '/auth';

    if (nextPath !== path) await this.$router.push(nextPath);
  }
}
