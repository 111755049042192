var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"transactions-table"},[(_vm.transactions.length)?_c('v-data-table',{attrs:{"dense":"","locale":"ru","headers":_vm.headers,"items":_vm.transactions,"items-per-page":5,"mobile-breakpoint":0,"footer-props":{
        itemsPerPageText: 'На странице:',
        pageText: `${_vm.page} из ${_vm.pageCount}`,
        showCurrentPage: _vm.pageCount > 1
    }},on:{"pagination":_vm.onPaginationEvent},scopedSlots:_vm._u([{key:"item.amount",fn:function({ item }){return [_c('v-chip',{staticClass:"amount",attrs:{"color":_vm.defineColor(item),"dark":""}},[_vm._v(" "+_vm._s(item.amount > 0 ? '+' : '-')+" "+_vm._s(_vm._f("formatMoney")(Math.abs(item.amount)))+" ")]),_c('v-menu',{attrs:{"bottom":"","max-width":"300","open-on-hover":false,"open-on-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('span',_vm._g(_vm._b({staticClass:"ml-2 status-icon",class:{
              awaiting: _vm.isAwaiting(item),
              rejected: _vm.wasRejected(item)
            },on:{"click":function($event){item.tooltip=true}}},'span',attrs,false),on),[(_vm.isAwaiting(item))?_c('v-progress-circular',{attrs:{"indeterminate":"","size":"16","width":"2","color":"primary"}}):_vm._e(),(_vm.wasRejected(item))?_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-alert-outline")])],1):_vm._e()],1)]}}],null,true),model:{value:(item.tooltip),callback:function ($$v) {_vm.$set(item, "tooltip", $$v)},expression:"item.tooltip"}},[_c('v-sheet',{staticClass:"pa-4 support-tooltip",attrs:{"dark":"","color":"black"}},[_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.errorMessage(item)))]),_c('br'),(item.idExternalPaymentGate || item.id)?_c('div',[_c('div',{staticClass:"py-2 text-center",staticStyle:{"font-size":"larger"}},[_c('strong',[_vm._v(_vm._s(item.idExternalPaymentGate || item.id))])]),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"white","width":"100%","depressed":"","outlined":""},on:{"click":function($event){return _vm.copyToClipboard(item)}}},[_vm._v(" Скопировать код ")])],1):_vm._e()])],1)]}},{key:"item.date",fn:function({ item }){return [_c('div',{staticClass:"datetime"},[_c('div',[_c('v-icon',{staticClass:"table-icon"},[_vm._v("mdi-calendar")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(new Date(item.date)))+" ")],1),_c('div',[_c('v-icon',{staticClass:"table-icon"},[_vm._v("mdi-clock")]),_vm._v(" "+_vm._s(_vm._f("formatTime")(new Date(item.date))))],1)])]}}],null,false,2468648300)}):_c('div',{staticClass:"no-transactions-message"},[_vm._v("Нет транзакций")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }