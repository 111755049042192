
import { useApi } from '@/services/api';
import { Component, Vue } from 'vue-property-decorator';
import { LoyaltyProgramRuleDto } from '@torch-dev/kc-server-api';

@Component({
  name: 'BonusRules'
})
export default class extends Vue {
  rules: LoyaltyProgramRuleDto[] = [];
  api = useApi();

  get rulesSorted (): LoyaltyProgramRuleDto[] {
    return [...this.rules].sort((rule1, rule2) => rule1.min - rule2.min);
  }

  get rulesDescriptions (): string[] {
    return this.rulesSorted.map((rule, index) => {
      const limit = rule.min;
      const nextLimit = rule.max || this.rulesSorted[index + 1]?.min - 1;

      let text = `При пополнении карты на сумму от <b>${limit}&nbsp;руб.</b>`;

      if (nextLimit && nextLimit > limit) {
        text += ` до <b>${nextLimit}&nbsp;руб.</b>`;
      }

      const typeSymbol = rule.type === LoyaltyProgramRuleDto.type.FIXED
        ? ' бонусов'
        : '% от суммы на бонусный счет';

      text += ` вы получаете ${rule.value}${typeSymbol}`;

      return text;
    });
  }

  mounted () {
    this.loadRules();
  }

  async loadRules () {
    this.rules = await this.api.loyaltyProgram.getAll();
  }
}
