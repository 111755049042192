
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/modals/modal.vue';
import { ClientDto, KonturAccountDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';

type ModalAccountRenameParams = {
  account: KonturAccountDto
}

const api = useApi();

@Component({
  name: 'ModalCardRename',
  computed: mapState(['client']),
  components: { Modal }
})
export default class extends Vue {
  client!: ClientDto;

  showDialog = false;
  saveIsInProgress = false;
  account: KonturAccountDto | null = null;
  accountName: string | null = null;

  nameValid = true;
  nameRules = [
    (v?: string | null) => (typeof v === 'string' && v.trim().length > 0) || 'Поле не должно быть пустым',
    (v: string) => v.length < 64 || 'Слишком длинное имя'
  ];

  get saveBtnIsDisabled () {
    return !this.nameValid || this.accountName === this.account?.name;
  }

  async onSaveClick () {
    if (!this.account) return;
    if (!this.accountName) return;

    this.saveIsInProgress = true;
    await api.clients.renameAccount(this.client.id, this.account.id, { name: this.accountName });
    this.saveIsInProgress = false;
    this.showDialog = false;
    this.$root.$emit('account-updated');
  }

  onCancelClick () {
    this.showDialog = false;
  }

  mounted () {
    this.$root.$on('rename-account', ({ account }: ModalAccountRenameParams) => {
      this.showDialog = true;
      this.account = account;
      this.accountName = account.name;
    });
  }
}
