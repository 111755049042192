

import { Component, Prop, Vue } from 'vue-property-decorator';

enum Mode {
  DISPLAY,
  EDIT
}

@Component({
  name: 'InlineInput'
})
export default class extends Vue {
    @Prop({})
      value!: string;

    @Prop({})
      loading?: boolean;

    @Prop({})
      formatter?: (v: string) => string;

    @Prop()
      placeholder?: string;

    mode: Mode = Mode.DISPLAY;
    model: string = this.value;

    get isDisplayMode (): boolean {
      return this.mode === Mode.DISPLAY;
    }

    get formattedValue (): string {
      return this.formatter ? this.formatter(this.value) : this.value;
    }

    toEditMode (): void {
      this.model = this.value;
      this.mode = Mode.EDIT;
    }

    toDisplayMode (): void {
      this.mode = Mode.DISPLAY;
    }

    save (): void {
      this.$emit('value-changed', this.model);
      this.toDisplayMode();
    }
}
