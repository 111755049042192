import { StoreOptions } from 'vuex';
import { AuthParamsDto, ClientDto, PackageDto, ResourceDto } from '@torch-dev/kc-server-api';
import { setApiConfig, useApi } from '@/services/api';
import { getStoredUserDate, saveStoredUserData, clearStoredUserData } from '@/services/localStorage';
import { AppConfiguration, AppResources, getAppConfiguration, getAppResources } from '@/services/appConfiguration';

export type AppState = {
  client: ClientDto | null,
  resources: AppResources | null,
  configuration: AppConfiguration | null,
  availablePackages: PackageDto[],
  staticResources: ResourceDto[],
}

const api = useApi();

export default {
  state: {
    client: null,
    resources: getAppResources(),
    configuration: getAppConfiguration(),
    availablePackages: [],
    staticResources: []
  },
  mutations: {
    clientIsAuthorized: (state, client) => {
      state.client = client;
    },
    clientIsLoggedOut: (state) => {
      state.client = null;
    },
    availablePackagesAreLoaded: (state, packages: PackageDto[]) => {
      state.availablePackages = packages;
    },
    availableResourcesAreLoaded: (state, resources: ResourceDto[]) => {
      state.staticResources = resources;
    }
  },
  actions: {
    async authorizeClientByPhoneCode ({ commit }, { phone, code, isExistingUser }) {
      const authParams: AuthParamsDto = { method: AuthParamsDto.method.CODE, phone, code };
      const { token, userId } = isExistingUser
        ? await api.clientAuth.signIn(authParams)
        : await api.clientAuth.signUp(authParams);
      saveStoredUserData(token, userId);
      setApiConfig({ TOKEN: token });
      const client = await api.clients.getById(userId);
      commit('clientIsAuthorized', client);
    },
    async loadAuthorizedClient ({ commit }) {
      try {
        const storageData = getStoredUserDate();
        if (storageData) {
          const client = await api.clients.getById(storageData.userId);
          commit('clientIsAuthorized', client);
        }
      } catch (e) {
        clearStoredUserData();
        commit('clientIsLoggedOut');
      }
    },
    async loadAvailablePackages ({ commit }) {
      commit('availablePackagesAreLoaded', await api.packages.getAvailable());
    },
    async loadAvailableResources ({ commit }) {
      commit('availableResourcesAreLoaded', await api.resources.getAvailable());
    },
    logoutClient ({ commit }) {
      clearStoredUserData();
      commit('clientIsLoggedOut');
    }
  }
} as StoreOptions<AppState>;
