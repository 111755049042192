
import { Vue, Component } from 'vue-property-decorator';
import Modal from '@/components/modals/modal.vue';
import { ClientDto, KonturAccountDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';

export type ModalAccountLockParams = {
  account: KonturAccountDto
}

const api = useApi();

@Component({
  name: 'ModalCardLock',
  computed: mapState(['client']),
  components: { Modal }
})
export default class extends Vue {
  client!: ClientDto;

  showDialog = false;
  account: KonturAccountDto | null = null;

  async onLockClick () {
    if (!this.account) return;
    await api.clients.lockAccount(this.client.id, this.account.id);
    this.$root.$emit('account-updated');
    this.showDialog = false;
  }

  onCancelClick () {
    this.showDialog = false;
  }

  mounted () {
    this.$root.$on('lock-account', ({ account }: ModalAccountLockParams) => {
      this.showDialog = true;
      this.account = account;
    });
  }
}
