
import { Component, Vue } from 'vue-property-decorator';
import Navigation from '@/components/navigation.vue';
import { ClientDto } from '@torch-dev/kc-server-api';
import { mapState } from 'vuex';
import { AppResources } from '@/services/appConfiguration';

@Component({
  name: 'AppBar',
  computed: mapState(['client', 'resources']),
  components: { Navigation }
})
export default class extends Vue {
  client!: ClientDto | null;
  resources!: AppResources;
}
