export type LocalStorageData = {
  token: string,
  userId: number,
}

export function saveStoredUserData (token: string, userId: number) {
  localStorage.setItem('userToken', token);
  localStorage.setItem('userId', userId.toString());
}

export function getStoredUserDate (): LocalStorageData | null {
  const token = localStorage.getItem('userToken');
  const userId = parseInt(localStorage.getItem('userId') ?? '');

  if (!token || !userId) return null;

  return { token, userId };
}

export function clearStoredUserData (): void {
  localStorage.removeItem('userToken');
  localStorage.removeItem('userId');
}
