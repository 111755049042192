
import { Vue, Component, Prop } from 'vue-property-decorator';
import TransactionsTable from '@/views/dashboard/transactions-table.vue';
import { KonturPackageStateDto } from '@torch-dev/kc-server-api';

type PackagesListItem = {
  name: string,
  icon: string,
  value: number,
  prettyValue: string,
}

@Component({
  components: { TransactionsTable }
})
export default class packages extends Vue {
  @Prop()
    list!: KonturPackageStateDto[];

  get listItems (): PackagesListItem[] {
    return this.list.map(({ name, type, useCount, usedCount, timeFrom, timeTo }) => {
      let icon = '';
      let value = 0;
      let prettyValue = '';

      if (type === KonturPackageStateDto.type.COUNT && usedCount !== null && useCount !== null) {
        icon = 'mdi-counter';
        value = 100 - (usedCount / useCount * 100);
        prettyValue = `${useCount - usedCount} из ${useCount} проходов`;
      }

      if (type === KonturPackageStateDto.type.TIME && timeFrom !== null) {
        icon = 'mdi-clock-fast';

        if (!timeTo) {
          value = 100;
          prettyValue = 'Не активирован';
        } else {
          const timeToMs = Date.parse(timeTo);
          const timeFromMs = Date.parse(timeFrom);

          const durationMs = timeToMs - timeFromMs;
          const usedMs = Math.max(timeToMs - new Date().getTime(), 0);

          const format = (durationMs: number): string => {
            const MINUTE_MS = 1000 * 60;
            const HOUR_MS = MINUTE_MS * 60;
            const DAY_MS = HOUR_MS * 24;

            const days = Math.floor(durationMs / DAY_MS);
            const hours = Math.floor((durationMs - days * DAY_MS) / HOUR_MS);
            const minutes = Math.floor((durationMs - days * DAY_MS - hours * HOUR_MS) / MINUTE_MS);
            const result: string[] = [];

            if (durationMs > DAY_MS) {
              if (days > 0) result.push(`${days} дн`);
            } else if (durationMs > HOUR_MS) {
              if (hours > 0) result.push(`${hours} ч`);
              if (minutes > 0) result.push(`${minutes} мин`);
            } else {
              result.push(`${Math.min(minutes, 1)} мин`);
            }

            return result.join(' ');
          };

          value = usedMs / durationMs * 100;
          prettyValue = `${format(usedMs)} / ${format(durationMs)}`;
        }
      }

      return { name, icon, value, prettyValue };
    });
  }
}
