import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import DashboardView from '@/views/dashboard/dashboard-view.vue';
import SeasonTicketsView from '@/views/season-tickets/season-tickets-view.vue';
import AuthView from '@/views/auth-view.vue';
import CustomPage from '@/components/custom-page.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/auth',
    component: AuthView
  },
  {
    path: '/shop',
    component: SeasonTicketsView
  }, {
    path: '/dashboard',
    component: DashboardView
  }, {
    path: '/season-tickets',
    component: SeasonTicketsView
  }, {
    path: '/pages/:alias',
    component: CustomPage,
    props: true
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

export default router;
