
import { Vue, Component } from 'vue-property-decorator';
import { ClientDto, KonturAccountDto, PackageDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';
import Modal from '@/components/modals/modal.vue';

const api = useApi();

export type ModalPackagesParams = {
  account: KonturAccountDto;
}

@Component({
  name: 'ModalPackages',
  components: { Modal },
  computed: {
    ...mapState(['client', 'availablePackages']),
    PackageDto: () => PackageDto
  }
})
export default class extends Vue {
  client!: ClientDto;
  availablePackages!: PackageDto[];

  isLoading = true;
  showDialog = false;
  account: KonturAccountDto | null = null;
  async onPackageSelected ({ id: packageId, price: packagePrice }: PackageDto) {
    if (!this.account) return;

    const accountBalance = this.getAccountBalance();
    if (accountBalance < packagePrice) {
      this.showDialog = false;
      this.$root.$emit('refill-account', {
        accountId: this.account.id,
        amount: packagePrice - accountBalance
      });
      return;
    }

    await api.clients.addPackageToAccount(packageId, this.account.id, this.client.id);
    this.$root.$emit('package-added');
    this.showDialog = false;
  }

  getAccountBalance () {
    return this.account?.balance.reduce((value, dto) => value + dto.balance, 0) ?? 0;
  }

  mounted () {
    this.$root.$on('select-package', ({ account }: ModalPackagesParams) => {
      this.account = account;
      this.showDialog = true;
    });
  }
}
