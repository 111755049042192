
import { Component, Vue } from 'vue-property-decorator';
import { SeasonTicketInfo } from '@/views/season-tickets/model/season-ticket-info';
import { BonusType } from '@/views/season-tickets/model/bonus-type';
import SeasonTicket from '@/views/season-tickets/season-ticket.vue';
import SeasonTicketsSlider from '@/views/season-tickets/season-tickets-slider.vue';
import PhoneCodeForm from '@/components/phone-code-form.vue';
import { mapState } from 'vuex';
import PageMixin from '@/mixins/page-mixin';
import { useApi } from '@/services/api';
import { ClientDto } from '@torch-dev/kc-server-api';

@Component({
  mixins: [PageMixin],
  computed: mapState(['client']),
  components: { PhoneCodeForm, SeasonTicketsSlider, SeasonTicket }
})
export default class extends Vue {
  preSaleSeasonTickets: SeasonTicketInfo[] = [{
    name: 'Сезонный M',
    image: 'photo-1.jpg',
    color: '#69bd7d',
    price: 5000,
    bonus: 1500,
    bonusType: BonusType.Fixed
  }, {
    name: 'Сезонный X',
    image: 'photo-2.jpg',
    color: '#ffcd00',
    price: 15000,
    bonus: 8250,
    bonusType: BonusType.Fixed
  }, {
    name: 'Сезонный XL',
    image: 'photo-3.jpg',
    color: 'rgba(210,11,11,0.83)',
    price: 30000,
    bonus: 25500,
    bonusType: BonusType.Fixed
  }];

  api = useApi();
  client!: ClientDto | null;

  seasonTicket: SeasonTicketInfo | null = null;
  shouldShowPurchaseDialog = false;

  formStep = 1;
  paymentUrl: string | null = null;

  get callbackUrl (): string {
    return `${location.protocol}//${location.host}/processing-payment.html`;
  }

  onSeasonTicketSelected (seasonTicket: SeasonTicketInfo) {
    this.seasonTicket = seasonTicket;
    if (!this.client) {
      this.formStep = 1;
      this.$root.$once('create-refilled-account-code-verified', () => {
        this.formStep = 2;
        this.purchaseSeasonTicket();
      });
    } else {
      this.formStep = 2;
      this.purchaseSeasonTicket();
    }
    this.$root.$once('payment-completed', () => this.$router.push('/dashboard'));
    this.shouldShowPurchaseDialog = true;
  }

  async purchaseSeasonTicket () {
    if (!this.seasonTicket) {
      console.error('No season ticket selected, but purchase was initialized');
      return;
    }
    const amount = this.seasonTicket.price;
    const name = `АБОНЕМЕНТ ${this.seasonTicket.name.toUpperCase()}`;
    if (!amount) {
      console.error('Invalid amount for season ticket purchase');
      return;
    }
    const refillResponse = await this.api.clients.createAccountAndRefill({
      name,
      amount,
      isMutable: false,
      isRefillable: false,
      callbackUrl: this.callbackUrl
    });
    this.paymentUrl = refillResponse.paymentUrl;
  }
}
