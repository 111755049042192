import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { getAppResources } from '@/services/appConfiguration';

Vue.use(Vuetify);

const customColorTheme = getAppResources().colorTheme ?? {};

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#3451B6',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        ...customColorTheme
      }
    }
  }
});
