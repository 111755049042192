
import { Component, Vue, Watch } from 'vue-property-decorator';
import BonusRules from '@/components/bonus-rules.vue';
import Modal from '@/components/modals/modal.vue';
import { mapState } from 'vuex';
import { useApi } from '@/services/api';
import { ClientDto, LoyaltyProgramRuleDto } from '@torch-dev/kc-server-api';
import { AppConfiguration } from '@/services/appConfiguration';

type ModalRefillParams = {
  accountId?: string,
  amount?: number
}

@Component({
  name: 'ModalRefill',
  computed: mapState(['client', 'configuration']),
  components: { Modal, BonusRules }
})
export default class extends Vue {
  client!: ClientDto | null;
  configuration!: AppConfiguration;

  showDialog = false;
  accountId: string | null = null;
  amount: string | null = null;
  bonusAmount = 0;
  bonusRules: LoyaltyProgramRuleDto[] = [];
  paymentUrl: string | null = null;
  api = useApi();

  get amountNumber (): number {
    return this.amount ? parseInt(this.amount) : 0;
  }

  get callbackUrl (): string {
    return `${location.protocol}//${location.host}/processing-payment.html`;
  }

  showPaymentForm () {
    if (!this.client) return;
    if (!this.accountId) return;

    this.api.clients.refillAccount(this.accountId, this.client.id, {
      amount: this.amountNumber,
      /* TODO
      *   На Квани (и вообще везде, где будет использоваться Сбер как основная платежка)
      *   не нужен accountId. По-хорошему, надо его вообще убрать из запроса.
      *   Для этого надо реализовать на бэке запрос транзакции без id клиента и аккаунта.
      * */
      callbackUrl: location.protocol + '//' + location.host + location.pathname// + `?accountId=${this.accountId}`
    })
      .then(({ paymentUrl }) => {
        window.location.href = paymentUrl;
      });
  }

  mounted (): void {
    this.reset();
    this.loadRules();
    this.initRootEventsListeners();
    if (
      this.$route.query.transactionId &&
      this.$route.query.accountId
    ) {
      this.accountId = this.$route.query.accountId.toString();
      this.paymentUrl = this.callbackUrl + `?transactionId=${this.$route.query.transactionId}`;
      this.$router.push({ path: 'dashboard' });
      this.showDialog = true;
    }
  }

  reset (): void {
    this.showDialog = false;
    this.accountId = null;
    this.amount = null;
    this.bonusAmount = 0;
    this.paymentUrl = null;
  }

  async loadRules () {
    this.bonusRules = await this.api.loyaltyProgram.getAll();
  }

  initRootEventsListeners () {
    this.$root.$on('refill-account', ({ accountId, amount }: ModalRefillParams) => {
      this.accountId = accountId || '';
      this.amount = amount?.toString() || '';
      this.showDialog = true;
    });

    this.$root.$on('payment-ok-clicked', () => {
      this.reset();
      this.showDialog = false;
    });
  }

  @Watch('amount')
  async calculateBonus () {
    if (this.amountNumber > 0) this.bonusAmount = await this.api.loyaltyProgram.calculateBonus(this.amountNumber);
  }
}
