
import { Vue, Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { ClientDto } from '@torch-dev/kc-server-api';
import { AppConfiguration } from '@/services/appConfiguration';

type NavigationItem = {
  icon: string,
  name: string,
  path: string,
  authorizedOnly?: boolean
}

@Component({
  name: 'Navigation',
  computed: mapState(['client', 'configuration'])
})
export default class extends Vue {
  client!: ClientDto | null;
  configuration!: AppConfiguration;

  useMobileView = false;
  currentNavItem: NavigationItem | null = null;

  get navigation (): NavigationItem[] {
    const navigation: NavigationItem[] = [{
      icon: 'mdi-account-circle-outline',
      name: 'Профиль',
      path: '/dashboard',
      authorizedOnly: true
    }];

    return this.configuration.features.preSalesProgramEnabled
      ? [{
          icon: 'mdi-cart-outline',
          name: 'Абонементы',
          path: '/season-tickets'
        }, ...navigation]
      : navigation;
  }

  get filteredNavigation (): NavigationItem[] {
    return this.navigation.filter(item => !item.authorizedOnly || this.client);
  }

  findByRouterPath (): NavigationItem | null {
    return this.navigation.find(i => i.path === this.$router.currentRoute.path) || null;
  }

  goTo (item: NavigationItem) {
    if (this.$router.currentRoute.path !== item.path) {
      this.currentNavItem = item;
      this.$router.push(item.path);
    }
  }

  onResize () {
    this.useMobileView = window.innerWidth <= 600;
  }

  mounted (): void {
    this.currentNavItem = this.findByRouterPath();
    window.addEventListener('resize', () => this.onResize());
    this.onResize();
  }
}
