
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Transactions from '@/views/dashboard/transactions.vue';
import SkipassCard from '@/views/dashboard/skipass-card.vue';
import SkipassManagement from '@/views/dashboard/skipass-management.vue';
import { ClientDto, KonturAccountDto } from '@torch-dev/kc-server-api';
import { useApi } from '@/services/api';
import { mapState } from 'vuex';

type CarouselItem = KonturAccountDto | { id: string };

@Component({
  name: 'SkipassCards',
  computed: mapState(['client']),
  components: { Transactions, SkipassCard, SkipassManagement }
})
export default class extends Vue {
  client!: ClientDto;
  accounts: KonturAccountDto[] = [];
  carouselItems: CarouselItem[] = [];
  current = 0;
  loading = false;
  api = useApi();

  mounted () {
    const savedCurrent = localStorage.getItem('selected-card');
    this.current = savedCurrent ? parseInt(savedCurrent) : 0;
    this.$root.$on('account-created', () => this.loadAccounts().then(() => this.toLast()));
    this.$root.$on('account-attached', () => this.loadAccounts().then(() => this.toLast()));
    this.$root.$on('account-updated', () => this.loadAccounts());
    this.$root.$on('payment-ok-clicked', () => this.loadAccounts());
    this.$root.$on('package-added', () => this.loadAccounts());

    this.loadAccounts();
  }

  onPrevClick () {
    if (this.current > 0) this.current--;
  }

  onNextClick () {
    if (this.current < this.accounts.length) this.current++;
  }

  toLast () {
    this.current = this.accounts.length - 1;
  }

  isAccountCarouselItem (item: CarouselItem): item is KonturAccountDto {
    return item.id !== 'additional';
  }

  async loadAccounts () {
    this.loading = true;
    this.accounts = await this.api.clients.getAccounts(this.client.id);
    this.carouselItems = [
      ...this.accounts,
      { id: 'additional' }
    ];
    this.loading = false;
    this.$emit('account-selected', this.accounts[this.current]);
  }

  @Watch('current')
  onCurrentChanged () {
    localStorage.setItem('selected-card', this.current.toString());
    this.$emit('account-selected', this.accounts[this.current]);
  }
}
