import { Component, Vue } from 'vue-property-decorator';

@Component
export default class extends Vue {
  get appElement (): Element | null {
    return document.getElementsByClassName('app').item(0);
  }

  mounted () {
    this.appElement?.classList.add('page');
  }

  destroyed () {
    this.appElement?.classList.remove('page');
  }
}
